import React from "react";
import { css } from "@emotion/react";
import { fontSize20, fontSize24 } from "../style/fonts";
import { white } from "../style/colors";
import {
    mediaSmall,
    centeredBlock,
    fontSizeHeader,
    mediaMedium,
} from "../style/shared";

const bannerStyle = css`
    position: relative;
`;

const bannerHeaderStyle = css`
    color: ${white};
    ${fontSizeHeader};
    text-align: center;
    margin-bottom: 32px;

    ${mediaSmall} {
        text-align: left;
    }
`;

const bannerColumnsStyle = css`
    display: flex;
    justify-content: space-around;

    ${mediaSmall} {
        display: block;
    }
`;

const bannerColumnStyle = css`
    width: 50%;
    flex: None;

    box-sizing: border-box;
    padding: 24px 16px;
    display: flex;
    justify-content: center;

    ${mediaSmall} {
        display: block;
        width: 100%;
        padding: 0 20px;
    }

    ul {
        list-style: none;
    }
`;

const bannerContentStyle = css`
    background-color: #0a3a56;

    ${centeredBlock};
    padding-top: 60px;
    padding-bottom: 48px;
`;

const itemStyle = css`
    display: flex;
    align-items: center;
    margin: 18px 0;
`;

const iconStyle = css`
    height: 44px;
    width: 44px;
    margin-right: 20px;
    flex: none;
    padding-top: 4px;
`;

const textStyle = css`
    margin-right: 8px;
    padding-top: 6px;

    color: ${white};
    ${fontSize24};

    ${mediaMedium} {
        ${fontSize20};
    }
    line-height: 1.3;
`;

const Item = ({ icon, children }) => (
    <li css={itemStyle}>
        <svg
            css={iconStyle}
            aria-hidden="true"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
        >
            <use xlinkHref={icon} fill="#e26f26" />
        </svg>
        <p css={textStyle}>{children}</p>
    </li>
);

export default () => (
    <section css={bannerStyle}>
        <div css={bannerContentStyle}>
            <h2 css={bannerHeaderStyle}>Unsere Leistungen</h2>
            <div css={bannerColumnsStyle}>
                <div css={bannerColumnStyle}>
                    <ul>
                        <Item icon="#fa-calendar-check">
                            Jahresabschlüsse und
                            <br />
                            Steuererklärungen
                        </Item>
                        <Item icon="#fa-building">Unternehmensplanung</Item>
                        <Item icon="#fa-dharmachakra">
                            Steuerliche Beratung
                        </Item>
                    </ul>
                </div>
                <div css={bannerColumnStyle}>
                    <ul>
                        <Item icon="#fa-coins">Finanzbuchhaltung</Item>
                        <Item icon="#fa-chart-bar">
                            Betriebswirtschaftliche
                            <br />
                            Beratung
                        </Item>
                        <Item icon="#fa-piggy-bank">Lohnbuchhaltung</Item>
                    </ul>
                </div>
            </div>
        </div>
    </section>
);
