import React from "react";
import { Helmet } from "react-helmet";
import MailLink from "../components/MailLink";
import { css } from "@emotion/react";
import { fontSize20, fontSize18, fontSize24 } from "../style/fonts";
import { veryDarkGray } from "../style/colors";
import {
    mediumGreenHover,
    mediaMedium,
    mediaSmall,
    centeredBlock,
} from "../style/shared";
import TelLink from "./TelLink";

const sectionStyle = css`
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${centeredBlock};

    ${mediaSmall} {
        flex-direction: column;
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

const columnStyle = `
  ${mediaSmall} {
    width: 100%;
  }
`;

const leftColumnStyle = css`
    flex: auto;
    padding-right: 32px;

    ${mediaSmall} {
        padding-right: 0;
        padding-bottom: 16px;
    }

    ${columnStyle};
`;

const rightColumnStyle = css`
    flex: none;

    ${mediaSmall} {
        width: 100%;
        padding-top: 16px;
    }

    ${columnStyle};
`;

const headerStyle = css`
    ${fontSize24};
    font-weight: 700;
    color: ${veryDarkGray};
    margin-bottom: 8px;
`;

const addressStyle = css`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 52px;

    ${mediaMedium} {
        display: block;
    }
`;

const fontSize = css`
    ${fontSize20};
    font-style: normal;
    line-height: 1.3;

    ${mediaMedium} {
        ${fontSize18};
    }
`;

const addressColumn = css`
    ${fontSize};

    a {
        ${mediumGreenHover};
    }
`;

const addressColumnLeft = css`
    ${mediaMedium} {
        margin-bottom: 32px;
    }
`;

const addressColumnRight = css`
    margin: 0 auto;
`;

const mapStyle = css`
    border: 0;
    height: 380px;
    width: 380px;

    ${mediaSmall} {
        height: 300px;
        width: 100%;
    }
`;

export default () => (
    <section id="kontakt" css={sectionStyle}>
        <Helmet>
            <link rel="preconnect" href="https://maps.googleapis.com" />
            <link rel="preconnect" href="https://khms0.googleapis.com" />
            <link rel="preconnect" href="https://maps.gstatic.com" />
        </Helmet>
        <div css={leftColumnStyle}>
            <h3 css={headerStyle}>Kontakt</h3>
            <address css={addressStyle}>
                <div css={[addressColumn, addressColumnLeft]}>
                    Oliver Schmitt
                    <br />
                    Steuerberatungsgesellschaft&nbsp;mbH
                    <br />
                    Kassler Str. 30
                    <br />
                    61118 Bad Vilbel
                </div>
                <div css={[addressColumnRight, addressColumn]}>
                    Tel.:&nbsp;
                    <TelLink to="800660" />
                    <br />
                    Fax:&nbsp;
                    <TelLink to="800670" />
                    <br />
                    <br />
                    <MailLink to="info" />
                </div>
            </address>
            <h3 css={headerStyle}>Öffnungszeiten</h3>
            <p css={fontSize}>
                Montags bis Donerstags
                von&nbsp;8:00&nbsp;bis&nbsp;17:00&nbsp;Uhr
            </p>
            <p css={fontSize}>Freitags von&nbsp;8:00&nbsp;bis&nbsp;16:00 Uhr</p>
            <p css={fontSize}>Termin nach Vereinbarung</p>
        </div>
        <div css={rightColumnStyle}>
            <iframe
                title="Google Maps Karte"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2555.044677497629!2d8.73241595163486!3d50.17900097933789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd0f7be47ec38d%3A0x784522866d76d0a8!2sKasseler+Str.+30%2C+61118+Bad+Vilbel!5e0!3m2!1sen!2sde!4v1545438294249"
                frameBorder="0"
                css={mapStyle}
                allowFullScreen
            />
        </div>
    </section>
);
