import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { navigate } from "@reach/router";
import {
    darkGray,
    darkGreen,
    white,
    veryDarkGray,
    black,
} from "../style/colors";
import { fontSize28, fontSize16, fontSize18 } from "../style/fonts";
import { mediaSmall, mediaMobile, boxShadow } from "../style/shared";

const popupStyle = css`
    width: 600px;
    margin: auto;
    background-color: white;
    box-sizing: border-box;
    ${boxShadow};
    padding: 48px;
    position: relative;
    
    ${mediaMobile} {
        padding: 32px 16px;
    }

    h2 {
        ${fontSize28};
        color: ${darkGray};
        margin-bottom: 48px;
        text-align: center;

        ${mediaMobile} {
          margin-bottom: 32px;
        }
      }
    }

    p {
        ${fontSize16};
        color: ${veryDarkGray};
        margin-bottom: 48px;

        ${mediaMobile} {
          margin-bottom: 32px;
        }
    }
`;

const overlayStyle = css`
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
`;

const closeIconStyle = css`
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px; // enlarge click target

    color: ${darkGray};
    ${fontSize18};
    text-decoration: none;
`;

const okStyle = css`
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 50px;
    text-decoration: none;
    padding: 8px;
    border: 0;
    cursor: pointer;
    background-color: ${darkGreen};
    color: ${white};
    margin-right: auto;
    order: 4;

    ${fontSize18};

    ${mediaSmall} {
        ${fontSize16};
    }

    :hover {
        background-color: ${black};
    }
`;

const renderPopupContent = (popup) => {
    switch (popup) {
        case "newsletter":
            return (
                <React.Fragment>
                    <h2>Newsletter Anmeldung</h2>
                    <p>
                        Um die Anmeldung abzuschließen, klicken Sie bitte auf
                        den Link in der E-Mail, die wir soeben an Sie gesendet
                        haben.
                    </p>
                </React.Fragment>
            );
        default:
            return null;
    }
};

export default ({ popup }) => {
    if (!popup) {
        return null;
    }

    const content = renderPopupContent(popup);
    if (!content) {
        return null;
    }

    const onOutsideClick = (event) => {
        navigate("/", { replace: true });
    };

    const onPopupClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div css={overlayStyle} onClick={onOutsideClick}>
            <div css={popupStyle} onClick={onPopupClick}>
                <Link css={closeIconStyle} to="/" replace>
                    ✕
                </Link>
                {content}
                <Link css={okStyle} to="/" replace>
                    Ok
                </Link>
            </div>
        </div>
    );
};
