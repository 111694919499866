import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/react";
import { graphql, useStaticQuery } from "gatsby";
import { fontSize16, fontSize18, fontSize20 } from "../style/fonts";
import { veryDarkGray } from "../style/colors";
import {
    mediaSmall,
    mediaMedium,
    centeredBlock,
    fontSizeHeader,
    mediaMaxPageWidth,
    mediaMobile,
} from "../style/shared";

const mediaTwoRow = mediaMedium;

const oliImgStyle = css`
  ${mediaTwoRow} {
    max-width: 450px;
    width: 90%;
    margin 0 auto;
  }
`;

const sectionHeaderStyle = css`
    ${fontSizeHeader};
    color: ${veryDarkGray};
    margin-bottom: 32px;
`;

const sectionStyle = css`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    ${centeredBlock};

    ${mediaTwoRow} {
        flex-direction: column;
        align-items: center;
    }
`;

const columnStyle = css`
    flex: none;

    ${mediaTwoRow} {
        width: 100%;
    }
`;

const leftColumnStyle = css`
    width: 50%;
    ${columnStyle};

    ${mediaTwoRow} {
        order: 1;
    }
    margin-top: auto;
`;

const rightColumnStyle = css`
    width: calc(50% - 20px);
    padding-top: 48px;
    padding-bottom: 16px;

    ${mediaMaxPageWidth} {
        padding-top: 32px;
        padding-bottom: 32px;
        width: 50%;
    }

    ${mediaMedium} {
        padding-top: 16px;
        padding-bottom: 0;
    }

    ${mediaSmall} {
        padding-bottom: 32px;
    }

    ${columnStyle};

    margin: auto 0;
`;

const paragaphStyle = css`
    ${fontSize20};
    ${mediaMaxPageWidth} {
        ${fontSize18};
    }
    ${mediaMobile} {
        ${fontSize16};
    }
    margin-bottom: 16px;
`;

const nameStyle = css`
    ${fontSize20};
    font-style: italic;
    margin-top: 100px;

    ${mediaMaxPageWidth} {
        margin-top: 48px;
    }

    ${mediaMobile} {
        ${fontSize16};
        margin-top: 16px;
    }
`;

export default () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "oli-home.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 900, traceSVG: { color: "#d1cdc7" }) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);
    return (
        <section css={sectionStyle}>
            <div css={leftColumnStyle}>
                <Img
                    css={oliImgStyle}
                    fluid={data.file.childImageSharp.fluid}
                    loading="eager"
                    alt="Steuerberater Oliver Schmitt"
                />
            </div>
            <div css={rightColumnStyle}>
                <h1 css={sectionHeaderStyle}>
                    Ihr Steuerberater in Bad&nbsp;Vilbel
                </h1>
                <p css={paragaphStyle}>
                    Ich Oliver Schmitt und mein Team beraten Sie kompetent und
                    zukunftsorientiert bei all Ihren steuerlichen Belangen. Seit
                    1996 zentral im Raum Frankfurt gelegen hat sich unsere
                    Kanzlei auf die Beratung mittelständiger Unternehmen aus
                    Handel, Handwerk und Dienstleistungen spezialisiert.
                </p>
                <p css={paragaphStyle}>
                    Sie benötigen Unterstützung? Wir helfen Ihnen gerne den
                    Überblick zu behalten.
                </p>
                <p css={nameStyle}>Oliver Schmitt, Steuerberater</p>
            </div>
        </section>
    );
};
