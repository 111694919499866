import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Popups from "../components/Popups";
import { parseQuery } from "../utils";
import CtaBanner from "../components/CtaBanner";
import Contact from "../components/Contact";
import PortfolioBanner from "../components/PortfolioBanner";
import AboutUsTeaser from "../components/AboutUsTeaser";

export default ({ data, location }) => (
    <React.Fragment>
        <Helmet>
            <html lang="de" />
            <title>
                Oliver Schmitt - Ihr Steuerberater in Bad Vilbel / Frankfurt
            </title>
            <meta
                name="description"
                content="Sie wollen dem Finanzamt doch nichts schenken? Kommen Sie vorbei. Wir beraten Sie effizient und vorausschauend z.B. bei Jahresabschlüssen und Steuererklärungen."
            />
            <link rel="canonical" href="https://steuerschmitt.com" />
        </Helmet>
        <Popups popup={parseQuery(location).popup} />
        <AboutUsTeaser />
        <PortfolioBanner />
        <CtaBanner
            image={data.ctaCake.childImageSharp.fixed}
            alt="Kleines Mädchen mit einem Geburstagskuchen"
            angle={101}
            color="#4a1c36"
            offset={90}
            slogan="Sie wollen dem Finanzamt doch nichts schenken."
            ctaType="mail"
        />
        <Contact />
    </React.Fragment>
);

export const query = graphql`
    query {
        ctaCake: file(
            relativePath: { eq: "chris-benson-411764-unsplash.jpg" }
        ) {
            childImageSharp {
                fixed(height: 420) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
